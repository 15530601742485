<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!--        <el-button v-if="isAuth('wechat:wechatuser:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>-->
        <!--        <el-button v-if="isAuth('wechat:wechatuser:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>-->
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          prop="uid"
          header-align="center"
          align="center"
          label="微信OPENID">
      </el-table-column>
      <el-table-column
          prop="uname"
          header-align="center"
          align="center"
          label="姓名">
      </el-table-column>
      <el-table-column
          prop="wechatName"
          header-align="center"
          align="center"
          label="微信用户名">
      </el-table-column>
      <el-table-column
          prop="phone"
          header-align="center"
          align="center"
          label="电话">
      </el-table-column>
      <el-table-column
          prop="mainOrgName"
          header-align="center"
          align="center"
          label="机构">
      </el-table-column>
      <el-table-column
          prop="secondOrgName"
          header-align="center"
          align="center"
          label="所在库(圃)">
      </el-table-column>
      <el-table-column
          prop="miniProgVersion"
          header-align="center"
          align="center"
          label="小程序版本">
      </el-table-column>
      <el-table-column
          prop="raw"
          header-align="center"
          align="center"
          label="其它信息">
        <template slot-scope="scope">
          <template v-if="scope.row.raw">
            <el-avatar shape="square" size="large" :src="getImgUrl(scope.row.raw)"></el-avatar>
          </template>
        </template>
      </el-table-column>
      <el-table-column
          prop="updateTime"
          header-align="center"
          align="center"
          label="更新时间">
        <template slot-scope="scope">
          <span>{{ $utils.dateFormat('yyyy-MM-dd hh:mm', scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        fixed="right"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        width="150"-->
      <!--        label="操作">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.uid)">修改</el-button>-->
      <!--          <el-button type="text" size="small" @click="deleteHandle(scope.row.uid)">删除</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './wechatuser-add-or-update'
import PageMixin from "@/mixins/Page";

export default {
  data() {
    return {
      dataForm: {
        key: ''
      },
    }
  },
  mixins: [PageMixin],
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  methods: {
    getImgUrl(raw) {
      if (!raw) {
        return null;
      }
      const user = JSON.parse(raw)
      if (user) {
        return user.avatarUrl
      }
      return null;
    },
    // 获取数据列表
    getDataList() {
      this.doGetList('/wechat/wechatuser/list', {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'key': this.dataForm.key,
        'order': 'DESC',
        'sidx': 'update_time'
      })
    },
    // 删除
    deleteHandle(id) {
      this.doDelete('/wechat/wechatuser/delete', id)
    }
  }
}
</script>
